.ion-page.document-page .transaction-notes p {
    margin: 0;
}
.ion-page.document-page .document-details-holder div > span:first-child {
    font-size: 18px;
    font-weight: bold;
    width: 140px;
    display: inline-block;
}
.ion-page.document-page .document-details-holder span {
    display: inline-block;
}

.ion-page.document-page .document-paging-holder {
    text-align: center;
    width: 100%;
}
