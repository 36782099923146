.slot-row {
    border-bottom: 4px solid var(--ion-background-color);
}

.slot-row .slot-available {
    font-size: 14px;
    position: absolute;
    top: 8px;
    right: 16px;
}
