
.communications-page .communications-list {
    border-top: 1px solid var(--ion-color-light);
}

.communications-page .communication-type-filter .communication-type-filter-icon {
    font-size: 22px;
}

.communications-page .communication-type-date-container p.communication-sent {
    display: inline-block;
    margin-bottom: 0;
}

@media (min-width: 320px) {
    .communications-page .communication-type-filters-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media (min-width: 400px) {
    .communications-page .communication-type-filter {
        margin: 0 10px;
    }
    .communications-page .communication-type-filter .communication-type-filter-icon {
        font-size: 22px;
    }
}

