.ion-page.customer-selector-page {
    text-align: center;
}

.ion-page.customer-selector-page .company-logo-holder {
    background: var(--ion-color-light-tint);
    border-radius: 10px;
    margin: 8px;
}

.ion-page.customer-selector-page .company-logo-holder .company-logo {
    width: 10vw;
    max-height: 10vw;
}

@media (max-width: 576px) {
    .ion-page.customer-selector-page .company-logo-holder .company-logo {
        width: 20vw;
        max-height: 20vw;
    }
}

.ion-page.customer-selector-page .company-logo-holder ion-img::part(image) {
     border-radius: 10px;
 }

.ion-page.customer-selector-page .company-options {
    border-radius: 14px;
}
