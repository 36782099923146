.tab-footer {

}

.tab-footer ion-col {
    margin: 0;
    border: solid var(--ion-color-light-shade);
    border-width: 0 1px;
}

.tab-footer-button {
    border-radius: 0;
    margin: 0;
    color: var(--ion-color-dark);
    min-height: 56px;
}

.tab-footer-button-holder {
    height: 100%;
    margin: 0;
    min-height: 56px;
}
.tab-footer-button-holder-active {
    background-color: rgba(51, 153, 204, 0.10);
}

.tab-footer ion-icon {
    color: var(--ion-color-medium);
}

.tab-footer ion-icon.tab-footer-icon-active {
    color: var(--ion-color-primary);
    font-size: 10px;
}

.tab-footer ion-col.tab-active .tab-footer-button {
    padding: 0;
}
