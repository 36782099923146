.ion-page.customer-selector-page {
    text-align: center;
}
.ion-page.customer-selector-page .company-logo {
    width: 10vw;
    max-height: 10vw;
}

.ion-page.customer-selector-page .company-logo-holder {
    background: var(--ion-color-light-tint);
    border-radius: 10px;
}

.ion-page.customer-selector-page .company-logo-holder .company-logo {
    width: 10vw;
    max-height: 10vw;
}

.ion-page.customer-selector-page .company-options {
    border-radius: 14px;
}
