.ion-page.account-page .company-logo-holder {
    background: var(--ion-color-light-tint);
    border-radius: 10px;
}

.ion-page.account-page .company-logo-holder .company-logo {
    width: 10vw;
    max-height: 10vw;
}

.ion-page.account-page .company-options {
    border-radius: 14px;
}

.ion-page.account-page ion-grid.transactions-grid  {
    flex-wrap: nowrap;
    overflow-x: scroll !important;
    overflow-y: hidden;
}

.ion-page.account-page ion-grid.transactions-grid ion-row.transactions-grid-headings {
    font-weight: bold;
}

.ion-page.account-page ion-grid.transactions-grid ion-row.transaction-row {
    border-bottom: 2px solid var(--ion-border-color);
    flex-wrap: nowrap;
}
.ion-page.account-page ion-grid.transactions-grid ion-row.transaction-row:hover {
    background-color: var(--ion-color-light-shade);
}
.ion-page.account-page ion-grid.transactions-grid ion-row.transaction-row:nth-child(even):hover {
    background-color: var(--ion-color-light-shade);
}

.ion-page.account-page ion-grid.transactions-grid ion-row.transaction-row:nth-child(even) {
    background-color: var(--ion-color-light);
}

.ion-page.account-page ion-grid.transactions-grid .transaction-notes p {
    margin: 0;
}

