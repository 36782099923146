.ion-page.home-page ion-content {
    overflow-y: auto;
}

.ion-page.home-page .header-logo-wrapper {
    margin: 0;
    background-color: var(--ion-color-light);
}

.ion-page.home-page .header-logo {
    height: 20vh;
}

.ion-page.home-page .header-logo img {
    border-radius: 10px;
}
