
.ion-page.account-page ion-grid.banking-details-grid {

}


.banking-details-holder {

}

.banking-details div span:first-child {
    font-weight: bold;
    width: 85px;
    display: inline-block;
}


.ion-page.account-page ion-grid.banking-details-grid ion-col {
    font-weight: bold;
    width: 100px;
}

.ion-page.account-page ion-grid.banking-details-grid ion-col:first-of-type {
    font-weight: bold;
    padding-right: 0;
    width: 100px;
}

.ion-page.account-page ion-grid.banking-details-grid ion-col:last-of-type {
    font-weight: bold;
    width: auto;
}
