.slots-page .dates-toolbar-holder {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 16px 8px;
    background: var(--ion-background-color);
}

.slots-page .dates-toolbar {
    --background: var(--ion-background-color);
}

.slots-page .toolbar-content .dates-toolbar-content {
    overflow-x: scroll;
    white-space: nowrap;
}

.slots-page .dates-toolbar-holder .date-filter-week-changer .date-filter-week-changer-inner {
    text-align: center;
    width: 100%;
    height: 100%;
    display: block;
}
.slots-page .dates-toolbar-holder .date-filter-week-changer ion-icon {
    height: 100%;
    width: 24px;
    display: block;
    margin: auto;
    color: #00000089;
}

.slots-page .dates-toolbar-holder ion-chip {
    height: 40px;
    width: 55px;
    margin: 0 2px;
    text-align: center;
}
.slots-page .dates-toolbar-holder ion-chip .date-filter-option-inner {
    width: 100%;
    text-align: center;
}

.date-filter-option.date-filter-option-is-booked .date-filter-option-booked-marker {
    background: var(--ion-color-primary);
    height: 5px;
    width: 10px;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
}

.date-filter-option.date-filter-option-is-waiting-booked:not(.date-filter-option-is-booked) .date-filter-option-booked-marker {
    background: var(--ion-color-medium);
    height: 5px;
    width: 10px;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
}

.slots-page ion-chip.chip-label {
    pointer-events: none;
    font-weight: 600;
}

@media (max-width: 576px) {
    .slots-page .dates-toolbar-holder {
        padding: 6px 8px;
    }

    .slots-page .dates-toolbar-holder ion-chip {
        width: 37px;
        border-width: 0 !important;
    }
}

@media (max-width: 450px) {
    .slots-page .dates-toolbar-holder {
        padding: 6px 8px;
    }

    .slots-page .dates-toolbar-holder ion-chip {
        width: 34px;
    }
}


.slots-page .filters-toolbar {
    border-bottom: 1px solid var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)));
    border-color: var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)));
}
