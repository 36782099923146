.ion-page ion-header ion-toolbar ion-title {
    text-align: center;
}

.swl-page-loading {
    background: rgba(0,0,0,0);
}

.network-warning-bar {
    background: var(--ion-color-danger);
    font-weight: 500;
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-align: center;
    vertical-align: middle;
}
