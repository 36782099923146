.communication-modal .communication-message, .communication-modal small {
    color: var(--ion-color-dark);
}
.communication-modal {
    --height: auto;
}
.communication-modal .ion-page {
    position: relative;
    display: block;
    contain: content;
}
.communication-modal .communication-message{
    max-height: 50vh;
    overflow: scroll;
}

.communication-modal .communication-type {
    margin: 0;
}
