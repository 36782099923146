
.slot-details-heading h2 {
    font-size: 20px;
    font-weight: 500;
}

.slot-details-description-inner {
    max-height: 50vh;
    overflow-y: scroll;
}
