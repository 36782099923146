@media (prefers-color-scheme: dark) {
    ion-header.app-header > ion-toolbar.app-header-toolbar {
        --background: var(--ion-color-light);
    }

    ion-header.app-header > ion-toolbar.app-header-toolbar.app-header-toolbar-primary {
        --background: var(--ion-color-primary);
    }

    ion-header.app-header > ion-toolbar.app-header-toolbar ,
    ion-header.app-header > ion-toolbar.app-header-toolbar ion-icon,
    ion-header.app-header > ion-toolbar.app-header-toolbar ion-title,
    ion-header.app-header > ion-toolbar.app-header-toolbar ion-back-button,
    ion-header.app-header > ion-toolbar.app-header-toolbar ion-button  {
        color: var(--ion-color-primary-contrast) !important;
    }
}

.menu-space-holder {
    width: 48px;
}

/* Tweak header with subtitle */
ion-header.app-header > ion-toolbar.app-header-toolbar ion-title h4 {
    margin-bottom: -4px;
}
ion-header.app-header > ion-toolbar.app-header-toolbar ion-title ion-text {
    font-weight: 300;
}

ion-header.app-header > ion-toolbar.app-header-toolbar .app-back-button {
    margin-left: 8px;
}
